import { _request } from './request';

export type OrdainedModel = {
  firstName: string;
  middleName: string;
  lastName: string;
  address: string;
  city: string;
  state: string;
  age_confirmed: boolean;
  minister_requested: boolean;
  terms_accepted: boolean;
  hash?: string;
};

export type OrdainedResponseModel = {
  first_name: string;
  middle_name: string;
  last_name: string;
  address: string;
  city: string;
  state: string;
  age_confirmed: boolean;
  minister_requested: boolean;
  terms_accepted: boolean;
  hash: string;
};

export type SaveOrdainedResponse = {
  success: boolean;
  message: string;
};

export type GetOrdainedResponse = {
  success: boolean;
  message: string;
  data: OrdainedResponseModel;
};

export const saveOrdained = async (
  ordained: OrdainedModel,
  ceremonyId: number
) => {
  const responseBody = await _request<SaveOrdainedResponse>({
    url: `ordained/save`,
    method: 'POST',
    body: {
      ordained: ordained,
      ceremonyId: ceremonyId,
    },
  });
  return responseBody;
};

export const getOrdained = async () => {
  const responseBody = await _request<GetOrdainedResponse>({
    url: `ordained`,
    method: 'GET',
  });
  return responseBody;
};

export const updateOrdained = async ({
  firstName,
  middleName,
  lastName,
}: {
  firstName: string;
  middleName: string;
  lastName: string;
}) => {
  const responseBody = await _request<GetOrdainedResponse>({
    url: `ordained`,
    method: 'PUT',
    body: {
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
    },
  });
  return responseBody;
};
