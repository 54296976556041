import { useEffect, useRef, useState } from 'react';

import {
  deleteCeremonyMember,
  Member,
  MemberType,
} from '../../../api/ceremony';
import { validateEmail } from '../../../api/emailValidation';
import checkmark from '../../../assets/images/icons/Checkmark-green.svg';
import { getMemberTypeTitle } from '../../../helpers/dropdownHelper';
import { useDashboard } from '../../../provider/dashboardProvider';
import { useAccessibleModule } from '../../../provider/moduleAccessProvider';
import ConfirmationModal from '../../ConfirmationModal/ConfirmationModal';
import CustomTextInput from '../../forms/CustomTextInput';
import Button from '../../v2/Button/Button';
import InfoWarning from '../../v2/InfoWarning/InfoWarning';
import { Modal } from '../../v2/Modal/Modal';
import PreviewEmail from '../../v2/PreviewEmail/PreviewEmail';
import { ARTICLE_TYPES } from '../../v2/PreviewEmail/data/elevioArticles';
import { SelectField } from '../../v2/SelectField';
import { Typography } from '../../v2/Typography/Typography';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onButtonClick: (member: Member) => void;
  memberType: MemberType;
  memberForEditing?: Member;
  currentUser?: Member;
};
export const AddMemberModal = (props: Props) => {
  const { couple1, couple2, ceremony } = useDashboard();

  const {
    isOpen,
    onClose,
    onButtonClick,
    memberType,
    memberForEditing,
    currentUser,
  } = props;

  const [inviteLinkCopied, setInviteLinkCopied] = useState(false);
  const [member, setMember] = useState({} as Member);
  const [roleNeedsUpdate, setRoleNeedsUpdate] = useState(false);
  const [emailsDiffer, setEmailsDiffer] = useState(false);
  const [memberRole, setMemberRole] = useState<MemberType>();
  const [memberInvited, setMemberInvited] = useState(false);
  const [elevioArticleType, setElevioArticleType] = useState<
    ARTICLE_TYPES | undefined
  >(undefined);
  const [emailError, setEmailError] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const [showConfirmEmailModal, setShowConfirmEmailModal] = useState(false);

  const { hasAccessToInviteOfficiant, hasAccessToInviteWeddingPlanner } =
    useAccessibleModule();

  const checkIsEmailValid = async (email: string) => {
    const response = await validateEmail(email);
    if (response.success) {
      return response.data.isValid;
    }
    return false;
  };

  const handleButtonClick = async (member: Member, skipValidation = false) => {
    let hasErrors = false;
    let showEmailModal = false;
    if (!firstName) {
      setFirstNameError('Please enter first name');
      hasErrors = true;
    }
    if (!lastName) {
      setLastNameError('Please enter last name');
      hasErrors = true;
    }
    if (!member.email) {
      setEmailError('Please enter an email address');
      hasErrors = true;
    } else {
      //FE email validation - triggers only when email is not empty
      let isEmailValid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
        member.email
      );
      if (!isEmailValid) {
        showEmailModal = true;
      }
      if (isEmailValid) {
        //BE email validation - triggers only when FE validation is passed
        isEmailValid = skipValidation
          ? true
          : await checkIsEmailValid(member.email);
        if (!isEmailValid) {
          hasErrors = true;
          showEmailModal = true;
        }
      } else {
        hasErrors = true;
      }
    }

    if (hasErrors) {
      showEmailModal && setShowConfirmEmailModal(true);
      setEmailError('Please enter a valid email address');
    }

    if (!hasErrors) {
      setEmailError('');
      onButtonClick(member);
    }
  };

  const myRefname = useRef<HTMLInputElement>(null);
  const closeModalOpeningElevio: () => void = () => {
    onClose();
    if (myRefname && myRefname.current) myRefname.current.click();
  };

  useEffect(() => {
    if (memberType === MemberType.officiant) {
      setElevioArticleType('INVITE_OFFICIANT');
    }

    if (memberForEditing?.id === couple1?.id) {
      setElevioArticleType('INVITE_PARTNER_A');
    }

    if (memberForEditing?.id === couple2?.id) {
      setElevioArticleType('INVITE_PARTNER_B');
    }

    if (memberType === MemberType.weddingPlanner) {
      setElevioArticleType('INVITE_PLANNER');
    }
  }, [memberForEditing, memberType]);

  useEffect(() => {
    if (memberForEditing) {
      setMember(memberForEditing);
      memberForEditing.legal_name?.includes('TBD')
        ? setRoleNeedsUpdate(true)
        : setMemberRole(memberForEditing.member_type);
      if (memberForEditing.legal_name) {
        setFirstName(memberForEditing.legal_name.split(' ')[0]);
        setLastName(memberForEditing.legal_name.split(' ')[1]);
      }
    }

    if (
      !memberForEditing ||
      !memberForEditing.legal_name ||
      !memberForEditing.legal_name?.includes('TBD')
    ) {
      setMember((old) => ({ ...old, member_type: memberType }));
      setMemberRole(memberType);
    }
    if (memberForEditing && memberForEditing.email) setMemberInvited(true);
  }, [memberForEditing, memberType]);

  const memberAcceptedInvite = member.user_id != undefined;

  const updateFormField = (property: string | number, value: string) => {
    const updated = { ...member };
    switch (property) {
      case 'legal_name': {
        updated.legal_name = value;
        break;
      }
      case 'first_name': {
        updated.legal_name = `${value} ${
          updated.legal_name?.split(' ')[1] || ''
        }`;
        updated.preferred_name = value;
        setFirstName(value);
        if (value) {
          setFirstNameError('');
        }
        break;
      }
      case 'last_name': {
        updated.legal_name = `${
          updated.legal_name?.split(' ')[0] || ''
        } ${value}`;
        setLastName(value);
        if (value) {
          setLastNameError('');
        }
        break;
      }
      case 'email': {
        updated.email = value;
        if (memberForEditing?.email !== value) {
          setEmailsDiffer(true);
        } else {
          setEmailsDiffer(false);
        }
        setEmailError('');
        break;
      }
      case 'member_type': {
        updated.member_type = value as MemberType;
        setMemberRole(value as MemberType);
        setRoleNeedsUpdate(false);
        break;
      }
    }
    // updated.member_type = property !== 'member_type' ? memberType : updated.member_type;

    setMember({ ...updated });
  };

  let mainButtonText = '';

  if (memberAcceptedInvite && !emailsDiffer) mainButtonText = 'Update';
  else if (memberAcceptedInvite && emailsDiffer) {
    mainButtonText = 'Update & Resend Invite';
  } else if (memberForEditing && memberForEditing.email) {
    mainButtonText = 'Resend Invite';
  } else mainButtonText = 'Send Invite';

  const copyInviteLink = () => {
    if (!currentUser?.ceremony_id || !memberForEditing?.email) return;

    const roleNumber =
      memberType === MemberType.bride ||
      memberType === MemberType.other ||
      memberType === MemberType.groom
        ? '1'
        : memberType === MemberType.officiant
        ? '2'
        : memberType === MemberType.guests
        ? '3'
        : memberType === MemberType.weddingPlanner
        ? '4'
        : '3';

    const base64Email = btoa(memberForEditing.email);
    const base64ceremonyId = btoa(currentUser.ceremony_id.toString());

    const inviteLink =
      window.location.origin +
      `/review?r=${roleNumber}&e=${base64Email}&cid=${base64ceremonyId}`;

    navigator.clipboard.writeText(inviteLink);

    setInviteLinkCopied(true);

    setTimeout(() => {
      setInviteLinkCopied(false);
    }, 3000);
  };

  const getInviteButtonText = () => {
    if (inviteLinkCopied)
      return (
        <>
          Link Copied{' '}
          <img src={checkmark} className='inline ml-1' alt='checkmark' />
        </>
      );
    else if (
      memberType === MemberType.bride ||
      memberType === MemberType.groom ||
      memberType === MemberType.other
    )
      return 'Copy Invite Link for Partner';
    else if (memberType === MemberType.officiant)
      return 'Copy Invite Link for Officiant';
    else if (memberType === MemberType.guests)
      return 'Copy Invite Link for Guests';
    else if (memberType === MemberType.weddingPlanner)
      return 'Copy Invite Link for Wedding Planner';
    return 'Copy Invite Link';
  };

  const deleteMember = async () => {
    if (!memberForEditing?.id) {
      return;
    }

    try {
      const response = await deleteCeremonyMember(memberForEditing.id);

      if (response.success) {
        onClose();
        location.reload();
      } else {
        console.log(response);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <>
        <div className='NewPeopleModal'>
          <div className='flex flex-row justify-between items-center'>
            <Typography type='display-100'>
              {`${
                memberForEditing && memberForEditing.email ? 'Edit' : 'Add'
              } ${
                memberForEditing?.legal_name === 'TBD TBD'
                  ? 'Partner'
                  : getMemberTypeTitle(memberType)
              }`}
            </Typography>
            {memberForEditing?.user_id !== ceremony?.created_by && (
              <Button
                onClick={() => {
                  setShowDeleteConfirmationModal(true);
                }}
                variant='text'
              >
                Remove Member
              </Button>
            )}
          </div>

          {memberForEditing
            ? !memberAcceptedInvite &&
              memberInvited && (
                <InfoWarning
                  text='Pending: You already sent an invite but it hasn’t been accepted yet. You can resend it to the same email address or update it and send it to a different one.'
                  variant='yellow'
                />
              )
            : null}

          <div>
            <CustomTextInput
              label='First name'
              placeholder='Name'
              size={200}
              value={
                member.legal_name?.split(' ')[0].includes('TBD')
                  ? ''
                  : member.legal_name?.split(' ')[0] || ''
              }
              onChange={(e) => {
                updateFormField('first_name', e.target.value);
              }}
              error={!!firstNameError}
              helperText={firstNameError}
            />

            <CustomTextInput
              label='Last name'
              placeholder='Name'
              size={200}
              value={
                member.legal_name?.split(' ')[1] &&
                member.legal_name?.split(' ')[1].includes('TBD')
                  ? ''
                  : member.legal_name?.split(' ')[1] || ''
              }
              onChange={(e) => {
                updateFormField('last_name', e.target.value);
              }}
              error={!!lastNameError}
              helperText={lastNameError}
            />

            {currentUser?.user_id === ceremony?.created_by && (
              <SelectField
                className='w-full border border-forest-400 rounded-none h-10 mb-2'
                label='Role'
                labelVariant='outside'
                fontVariant='grotesk'
                onChange={(newValue) => {
                  updateFormField('member_type', newValue as string);
                }}
                value={memberRole}
                options={[
                  {
                    label: getMemberTypeTitle(MemberType.bride),
                    value: MemberType.bride,
                  },
                  {
                    label: getMemberTypeTitle(MemberType.groom),
                    value: MemberType.groom,
                  },
                  {
                    label: getMemberTypeTitle(MemberType.other),
                    value: MemberType.other,
                  },
                  {
                    label: getMemberTypeTitle(MemberType.officiant),
                    value: MemberType.officiant,
                    disabled: !hasAccessToInviteOfficiant,
                  },
                  {
                    label: getMemberTypeTitle(MemberType.weddingPlanner),
                    value: MemberType.weddingPlanner,
                    disabled: !hasAccessToInviteWeddingPlanner,
                  },
                  {
                    label: getMemberTypeTitle(MemberType.guests),
                    value: MemberType.guests,
                  },
                ]}
              />
            )}

            <CustomTextInput
              label='Email address'
              placeholder='Email address'
              size={200}
              value={member.email}
              onChange={(e) => {
                updateFormField('email', e.target.value);
              }}
              error={!!emailError}
              helperText={emailError}
              disabled={
                
                memberForEditing?.user_id === currentUser?.user_id
              }
            />
            <div>
              {memberForEditing?.user_id !== currentUser?.user_id  && elevioArticleType && (
                <PreviewEmail
                  text='Preview invite email'
                  type={elevioArticleType}
                />
              )}
              {memberForEditing?.user_id === currentUser?.user_id && (
                <Button
                  onClick={() => {
                    location.href = '/settings';
                  }}
                  variant='text'
                >
                  Edit your email
                </Button>
              )}
            </div>
            <div
              className={
                memberAcceptedInvite
                  ? 'flex flex-row mt-6 justify-end'
                  : 'flex flex-row mt-6 justify-between'
              }
            >
              {memberForEditing &&
              memberForEditing.email &&
              !memberAcceptedInvite ? (
                <Button variant='secondary' onClick={copyInviteLink}>
                  {getInviteButtonText()}
                </Button>
              ) : null}
              <Button
                disabled={
                  member.legal_name?.split(' ')[0].includes('TBD') ||
                  (member.legal_name?.split(' ')[1] &&
                    member.legal_name?.split(' ')[1].includes('TBD')) ||
                  roleNeedsUpdate
                }
                onClick={() => {
                  void handleButtonClick(member);
                }}
              >
                {mainButtonText}
              </Button>
            </div>
          </div>
        </div>
        {showDeleteConfirmationModal && (
          <ConfirmationModal
            onAccept={() => {
              deleteMember();
              setShowDeleteConfirmationModal(false);
            }}
            closeModal={() => {
              setShowDeleteConfirmationModal(false);
            }}
            title='Are you sure you want to remove this member?'
            message='Removing this member will not delete their account, but it will remove them from the ceremony.'
            agreeText='Remove'
            disagreeText='Cancel'
          />
        )}

        {showConfirmEmailModal && (
          <ConfirmationModal
            onAccept={() => {
              void handleButtonClick(member, true);
              setShowConfirmEmailModal(false);
            }}
            closeModal={() => {
              setShowConfirmEmailModal(false);
            }}
            title='Is this email correct?'
            message={
              <div>
                <Typography type='body-400'>
                  <>
                  We believe this email might be unreachable, please double check and confirm this is the correct email address before inviting this collaborator:
                  </>
                </Typography>

                <Typography type='body-400' className='underline mt-2'>
                  {member.email}
                </Typography>
              </div>
            }
            agreeText='Email is correct'
            disagreeText='Change email'
          />
        )}
      </>
    </Modal>
  );
};
