import 'intro.js/introjs.css';
import { useEffect, useState } from 'react';

//new icons
import { useNavigate } from 'react-router-dom';

import { MemberType } from '../../../api/ceremony';
import {
  ChecklistItem,
  ChecklistItemType,
  ChecklistSubItemProps,
  getChecklistItemsForCeremony,
  saveChecklistItem,
} from '../../../api/checklistItems';
import { EventType } from '../../../api/friends';
import { updateUserCustomField } from '../../../api/user';
import {
  PaywallModal,
  PaywallModalTypes,
  UpsellGifTypes,
} from '../../../components/PaywallModal/PaywallModal';
import { CustomSwitch } from '../../../components/forms/CustomSwitch';
import circularProgressBar from '../../../components/v2/CircularProgressBar/CircularProgressBar';
import { GoToPaidCeremonyModal } from '../../../components/v2/GoToPaidCeremonyModal/GoToPaidCeremonyModal';
import { Typography } from '../../../components/v2/Typography/Typography';
import VideoModal from '../../../components/v2/VideoModal/VideoModal';
import { sendCustomerIoEventHandler } from '../../../helpers/customerIoHelper';
import {
  capitalizeFirstLetterOfEachWord,
  getCookie,
  setCookie,
} from '../../../helpers/helper';
import renderText from '../../../helpers/renderText';
import { Module } from '../../../hooks/useActiveModule';
import { useSpeakers } from '../../../hooks/useSpeakers';
import useVows from '../../../hooks/useVows';
import { useQAStatus } from '../../../provider/QAStatusProvider';
import { useAuth } from '../../../provider/authProvider';
import { useChecklist } from '../../../provider/checklistProvider';
import { useDashboard } from '../../../provider/dashboardProvider';
import { useAccessibleModule } from '../../../provider/moduleAccessProvider';
import { ModuleStatus } from '../../ModulesV2/enum';

import { Steps } from 'intro.js-react';

import CoupleChecklistItems from './CoupleChecklistItems';
import GiftcardBanner from './GiftcardBanner';
import { introJsStepsOfficiant } from './IntroJSStepsOfficiant';
import OfficiantChecklistItems from './OfficiantChecklistItems';
import RedeemGiftcardModal from './RedeemGiftcardModal';
import TourModal from './TourModal';
import { introJsSteps } from './introJsSteps.js';


type SubChecklistItemsByChecklistItem = {
  ['easyWins']: ChecklistSubItemProps[];
  ['buildCeremony']: ChecklistSubItemProps[];
  ['vows']: ChecklistSubItemProps[];
  ['speakers']: ChecklistSubItemProps[];
  ['more']: ChecklistSubItemProps[];
};

export const CeremonyChecklistDashboard = () => {

  const navigate = useNavigate();

  const params = window.location.search;
  const parsedParams = new URLSearchParams(params);
  const openOrdinationModal = parsedParams.get('open-ordination-modal');

  //new state
  const [tasksToShow, setTasksToShow] = useState<'couple' | 'officiant'>(
    'couple'
  );
  const [showCompleted, setShowCompletedFn] = useState<boolean>(true);

  const getShowCompletedCookieKey = () => {
    if (!currentUser || !currentUser.id || !ceremony || !ceremony.id)
      return false;
    else
      return (
        'showCompleted_' +
        currentUser.id.toString() +
        '_' +
        ceremony.id.toString()
      );
  };

  const setShowCompleted = (value: boolean) => {
    setShowCompletedFn(value);
    const cookieKey = getShowCompletedCookieKey();
    if (cookieKey) setCookie(cookieKey, value.toString(), 365);
  };

  const {
    ceremony,
    currentUser,
    currentRole,
    couple1,
    couple2,
    setMemberForEditing,
    setMemberType,
    setAddModalOpen,
    setMobileMenuExpanded,
    invitePartnersModalOpen,
    setInvitePartnersModalOpen,
    giftcard,
    paidCeremonies,
    setOrdainedInfoModalOpen
  } = useDashboard();

  const { getVows, vowsCouple1, vowsCouple2 } = useVows();
  const { user, getProfile } = useAuth();

  const {
    hasAccessToDeclarationOfIntent,
    hasAccessToDirectionalQuestions,
    hasAccessToOfficiantRemarks,
    hasAccessToRitualsAndReadings,
    hasAccessToVowsAi,
    hasAccessToInviteOfficiant,
    hasAccessToInviteWeddingPlanner,
    fetchAccessibleModulesIdentifiers,
  } = useAccessibleModule();

  const { completedChecklistItemsCount, checklistItems } = useChecklist();
  const [showGoToPaidCeremonyModal, setShowGoToPaidCeremonyModal] =
    useState(false);

  const hideGoToPaidCeremonyKey = 'hide-go-to-paid-ceremony';

  const handleShowGoToPaidCeremonyModalClose = () => {
    if (
      paidCeremonies?.length == 1 &&
      paidCeremonies[0].id !== ceremony?.id &&
      user.custom_fields?.find(
        (customField) => customField.key === hideGoToPaidCeremonyKey
      ) == undefined
    )
      setShowGoToPaidCeremonyModal(true);
  };

  const handleDisplayInvitePartnersModal = () => {
    const hasBeenDisplayed = getCookie('invitePartnersModalDisplayed');

    if (
      !hasBeenDisplayed &&
      (hasAccessToVowsAi || hasAccessToOfficiantRemarks) &&
      (!couple1?.email || !couple2?.email)
    ) {
      setInvitePartnersModalOpen(true);
      setCookie('invitePartnersModalDisplayed', 'true', 365);
    }
  };

  useEffect (() => {
    openOrdinationModal && setOrdainedInfoModalOpen(true);
  },[openOrdinationModal])

  useEffect(() => {
    if (ceremony && currentUser) {
      setCookie('last_active_ceremony', ceremony.id.toString(), 365);
      void getVows(ceremony.id.toString(), currentUser, currentUser);
      void fetchAccessibleModulesIdentifiers(ceremony.id.toString());
      const showCompleteCookieKey = getShowCompletedCookieKey();
      if (showCompleteCookieKey) {
        const cookieValue = getCookie(showCompleteCookieKey);
        if (cookieValue) setShowCompletedFn(cookieValue === 'true');
      }
    }
  }, [ceremony]);

  useEffect(() => {
    handleShowGoToPaidCeremonyModalClose();
  }, [paidCeremonies]);

  useEffect(() => {
    if (hasAccessToOfficiantRemarks || hasAccessToVowsAi) {
      setIsNewUser(false);
      handleDisplayInvitePartnersModal();
    }
  }, [hasAccessToOfficiantRemarks, hasAccessToVowsAi]);

  const [showTourModal, setShowTourModal] = useState(false);
  const [showTour, setShowTour] = useState(false);
  const [isNewUser, setIsNewUser] = useState(true);

  const [isPaywallModalOpen, setIsPaywallModalOpen] = useState<boolean>(false);
  const [paywallModalReferrer, setPaywallModalReferrer] = useState<string>('');
  const [paywallModalType, setPaywallModalType] =
    useState<PaywallModalTypes>('ceremony');

  const [upsellGif, setUpsellGif] = useState<UpsellGifTypes | undefined>(
    undefined
  );

  const handleDoneTourClick = async () => {
    setShowTour(false);
    if (
      user &&
      user.custom_fields.filter(
        (f) => f.key === 'dashboard-ceremony-tour-done' && f.value === 'true'
      ).length === 0
    ) {
      try {
        await updateUserCustomField('dashboard-ceremony-tour-done', 'true');
        void getProfile(() => {});
        setCookie('ceremony-tour-done', 'true', 2);
      } catch (error) {}
    }
  };

  useEffect(() => {
    if (
      user &&
      user.custom_fields.filter(
        (f) => f.key === 'dashboard-ceremony-tour-done' && f.value === 'true'
      ).length === 0
    ) {
      const cookie = getCookie('ceremony-tour-done');

      !cookie && setShowTourModal(true);

      if (!cookie && currentUser) {
        const currentUserCreator = ceremony?.members?.find(
          (m) => m.user_id === currentUser?.created_by
        );

        currentUser.created_by !== currentUser.id &&
          void sendCustomerIoEventHandler('Invite Accepted', {
            sender_name: `${currentUserCreator?.legal_name ?? ''}`,
            sender_type: `${currentUserCreator?.member_type ?? ''}`,
            partner_a_name: `${couple1?.legal_name ?? ''}`,
            partner_b_name: `${couple2?.legal_name ?? ''}`,
            recipient_email: currentUser.email,
            recipient_name:
              currentUser.legal_name ?? currentUser.preferred_name ?? '',
            recipient_type: currentUser.member_type ?? '',
            invite_status: 'accepted',
          });
      }
    }
  }, [user]);
  const [checklistItemsForMember, setChecklistItemsForMember] = useState<
    ChecklistItem[]
  >([]);

  useEffect(() => {
    const vowsToCheck =
      couple1?.id === currentUser?.id ? vowsCouple1 : vowsCouple2;

    const vowsChecklistItem = checklistItemsForMember.find(
      (ci) => ci.type === ChecklistItemType.vows
    );

    if (vowsChecklistItem) {
      setVowsStatus(ModuleStatus.completed);
    } else if (vowsToCheck) {
      setVowsStatus(ModuleStatus.inProgress);
    }
  }, [checklistItemsForMember, vowsCouple1, vowsCouple2, currentUser]);

  useEffect(() => {
    const speechwritingSupportChecklistItem = checklistItemsForMember.find(
      (ci) => ci.type === ChecklistItemType.speechwritingSupport
    );
    if (speechwritingSupportChecklistItem) {
      setSpeechwritingSupportStatus(ModuleStatus.completed);
    }
    const publicSpeakingChecklistItem = checklistItemsForMember.find(
      (ci) => ci.type === ChecklistItemType.publicSpeaking
    );
    if (publicSpeakingChecklistItem) {
      setPublicSpeakingStatus(ModuleStatus.completed);
    }
    const premaritalCounselingChecklistItem = checklistItemsForMember.find(
      (ci) => ci.type === ChecklistItemType.premaritalCounseling
    );
    if (premaritalCounselingChecklistItem) {
      setPremaritalCounselingStatus(ModuleStatus.completed);
    }
    const getOrdainedChecklistItem = checklistItemsForMember.find(
      (ci) => ci.type === ChecklistItemType.getOrdained
    );
    if (getOrdainedChecklistItem) {
      setGetOrdainedStatus(ModuleStatus.completed);
    }
    const legalServicesChecklistItem = checklistItemsForMember.find(
      (ci) => ci.type === ChecklistItemType.legalServices
    );
    if (legalServicesChecklistItem) {
      setLegalServicesStatus(ModuleStatus.completed);
    }
    const prenumpChecklistItem = checklistItemsForMember.find(
      (ci) => ci.type === ChecklistItemType.prenump
    );

    if (prenumpChecklistItem) {
      setPrenump(ModuleStatus.completed);
    }
  }, [checklistItemsForMember]);

  const [ceremonyDesignerStatus, setCeremonyDesignerStatus] =
    useState<ModuleStatus>(ModuleStatus.notStarted);

  const [easyWinsStatus, setEasyWinsStatus] = useState<ModuleStatus>(
    ModuleStatus.notStarted
  );

  const [vowsStatus, setVowsStatus] = useState<ModuleStatus>(
    ModuleStatus.notStarted
  );
  const [toastsStatus, setToastsStatus] = useState<ModuleStatus>(
    ModuleStatus.notStarted
  );
  const [speechwritingSupportStatus, setSpeechwritingSupportStatus] =
    useState<ModuleStatus>(ModuleStatus.notStarted);
  const [publicSpeakingStatus, setPublicSpeakingStatus] =
    useState<ModuleStatus>(ModuleStatus.notStarted);
  const [premaritalCounselingStatus, setPremaritalCounselingStatus] =
    useState<ModuleStatus>(ModuleStatus.notStarted);
  const [getOrdainedStatus, setGetOrdainedStatus] = useState<ModuleStatus>(
    ModuleStatus.notStarted
  );
  const [legalServicesStatus, setLegalServicesStatus] = useState<ModuleStatus>(
    ModuleStatus.notStarted
  );
  const [prenump, setPrenump] = useState<ModuleStatus>(ModuleStatus.notStarted);
  const [moreStatus, setMoreStatus] = useState<ModuleStatus>(
    ModuleStatus.notStarted
  );
  const [weddingDateStatus, setWeddingDateStatus] = useState<ModuleStatus>(
    ModuleStatus.notStarted
  );
  const [partnerStatus, setPartnerStatus] = useState<ModuleStatus>(
    ModuleStatus.notStarted
  );
  const [partnerAStatus, setPartnerAStatus] = useState<ModuleStatus>(
    ModuleStatus.notStarted
  );
  const [partnerBStatus, setPartnerBStatus] = useState<ModuleStatus>(
    ModuleStatus.notStarted
  );
  const [venueStatus, setVenueStatus] = useState<ModuleStatus>(
    ModuleStatus.notStarted
  );
  const [guestsStatus, setGuestsStatus] = useState<ModuleStatus>(
    ModuleStatus.notStarted
  );
  const [officiantStatus, setOfficiantStatus] = useState<ModuleStatus>(
    ModuleStatus.notStarted
  );
  const [weddingPlannerStatus, setWeddingPlannerStatus] =
    useState<ModuleStatus>(ModuleStatus.notStarted);
  const [checklistItemsCompletedCount, setChecklistItemsCompletedCount] =
    useState<number>(0);
  const [expandedChecklistItem, setExpandedChecklistItem] = useState<number>(0);
  const [userSetExpandedChecklistItem, setUserSetExpandedChecklistItem] =
    useState<boolean>(false);

  useEffect(() => {
    let result = 0;
    const statusesArr: ModuleStatus[] = [
      ceremonyDesignerStatus,
      vowsStatus,
      toastsStatus,
      /* speechwritingSupportStatus,
      publicSpeakingStatus, */
      moreStatus,
      easyWinsStatus,
    ];

    statusesArr.forEach((s) => {
      if (s === ModuleStatus.completed) {
        result++;
      }
    });

    setChecklistItemsCompletedCount(result);

    renderSubChecklistItems();
  }, [
    ceremonyDesignerStatus,
    vowsStatus,
    toastsStatus,
    speechwritingSupportStatus,
    publicSpeakingStatus,
    premaritalCounselingStatus,
    legalServicesStatus,
    weddingDateStatus,
    partnerStatus,
    venueStatus,
    guestsStatus,
    officiantStatus,
    weddingPlannerStatus,
    checklistItemsForMember,
    moreStatus,
    easyWinsStatus,
    hasAccessToDeclarationOfIntent,
    hasAccessToDirectionalQuestions,
    hasAccessToOfficiantRemarks,
    hasAccessToRitualsAndReadings,
    hasAccessToVowsAi,
    prenump,
    getOrdainedStatus,
  ]);

  /* Dynamic Texts */
  const set_wedding_date = renderText('set_wedding_date');
  const share_love_story = renderText('share_love_story');
  const checklist_more_element_1_title = renderText(
    'checklist_more_element_1_title'
  );
  const checklist_more_element_2_title = renderText(
    'checklist_more_element_2_title'
  );
  const checklist_more_element_3_title = renderText(
    'checklist_more_element_3_title'
  );

  const renderSubChecklistItems = () => {
    if (!ceremony || !currentUser || !couple1 || !couple2) {
      return;
    }

    const otherMember = ceremony?.members?.find((m) => m.id !== currentUser.id);

    if (!otherMember) {
      return;
    }

    const subChecklistItems: SubChecklistItemsByChecklistItem = {
      ['easyWins']: [
        {
          title: 'Invite your partner',
          buttonTitle:
            checklistItemsForMember.find(
              (c) => c.type === ChecklistItemType.invitePartner
            ) || partnerStatus === ModuleStatus.completed
              ? ''
              : 'Invite',

          textButtonTitle:
            checklistItemsForMember.find(
              (c) => c.type === ChecklistItemType.invitePartner
            ) || partnerStatus === ModuleStatus.completed
              ? 'Edit'
              : '',
          onButtonClick: () => {
            const memberForEditing = ceremony?.members
              ?.filter(
                (m) =>
                  m.member_type === MemberType.groom ||
                  m.member_type === MemberType.bride ||
                  m.member_type === MemberType.other
              )
              .find((m) => m.id !== currentUser.id && !m.email);
            if (memberForEditing) {
              setMemberType(memberForEditing.member_type);
              setMemberForEditing(memberForEditing);
              setAddModalOpen(true);
              //setMobileMenuExpanded(true);
            }
          },
          status:
            partnerStatus === ModuleStatus.completed ||
            checklistItemsForMember.find(
              (c) => c.type === ChecklistItemType.invitePartner
            )
              ? ModuleStatus.completed
              : ModuleStatus.notStarted,
          onTextButtonClick: () => {
            const memberForEditing = ceremony?.members
              ?.filter(
                (m) =>
                  m.member_type === MemberType.groom ||
                  m.member_type === MemberType.bride ||
                  m.member_type === MemberType.other
              )
              .find((m) => m.id !== currentUser.id);
            partnerStatus === ModuleStatus.completed ||
            checklistItemsForMember.find(
              (c) => c.type === ChecklistItemType.invitePartner
            )
              ? memberForEditing &&
                (setMemberType(memberForEditing.member_type),
                setMemberForEditing(memberForEditing),
                setAddModalOpen(true),
                setMobileMenuExpanded(true))
              : saveItem(
                  ChecklistItemType.invitePartner,
                  ceremony.id,
                  currentUser.id,
                  true
                );
          },
          hidden: currentUser?.member_type === MemberType.officiant,
        },
        {
          title: `Invite ${couple1.preferred_name || ''}`,
          buttonTitle:
            checklistItemsForMember.find(
              (c) => c.type === ChecklistItemType.invitePartnerA
            ) || partnerAStatus === ModuleStatus.completed
              ? ''
              : 'Invite',
          onButtonClick: () => {
            const memberForEditing = couple1;
            if (memberForEditing) {
              setMemberType(memberForEditing.member_type);
              setMemberForEditing(memberForEditing);
              setAddModalOpen(true);
              //setMobileMenuExpanded(true);
            }
          },
          status:
            partnerAStatus === ModuleStatus.completed ||
            checklistItemsForMember.find(
              (c) => c.type === ChecklistItemType.invitePartnerA
            )
              ? ModuleStatus.completed
              : ModuleStatus.notStarted,
          textButtonTitle:
            partnerAStatus === ModuleStatus.completed ||
            checklistItemsForMember.find(
              (c) => c.type === ChecklistItemType.invitePartnerA
            )
              ? 'Edit'
              : 'Skip this step',
          onTextButtonClick: () => {
            const memberForEditing = couple1;
            partnerAStatus === ModuleStatus.completed ||
            checklistItemsForMember.find(
              (c) => c.type === ChecklistItemType.invitePartnerA
            )
              ? memberForEditing &&
                (setMemberType(memberForEditing.member_type),
                setMemberForEditing(memberForEditing),
                setAddModalOpen(true),
                setMobileMenuExpanded(true))
              : saveItem(
                  ChecklistItemType.invitePartnerA,
                  ceremony.id,
                  currentUser.id,
                  true
                );
          },
          hidden:
            currentUser?.member_type === MemberType.groom ||
            currentUser?.member_type === MemberType.bride ||
            currentUser?.member_type === MemberType.other,
        },
        {
          title: `Invite ${couple2.preferred_name || ''}`,
          buttonTitle:
            checklistItemsForMember.find(
              (c) => c.type === ChecklistItemType.invitePartnerB
            ) || partnerBStatus === ModuleStatus.completed
              ? ''
              : 'Invite',
          onButtonClick: () => {
            const memberForEditing = couple2;
            if (memberForEditing) {
              setMemberType(memberForEditing.member_type);
              setMemberForEditing(memberForEditing);
              setAddModalOpen(true);
              //setMobileMenuExpanded(true);
            }
          },
          status:
            partnerBStatus === ModuleStatus.completed ||
            checklistItemsForMember.find(
              (c) => c.type === ChecklistItemType.invitePartnerB
            )
              ? ModuleStatus.completed
              : ModuleStatus.notStarted,
          textButtonTitle:
            partnerBStatus === ModuleStatus.completed ||
            checklistItemsForMember.find(
              (c) => c.type === ChecklistItemType.invitePartnerB
            )
              ? 'Edit'
              : 'Skip this step',
          onTextButtonClick: () => {
            const memberForEditing = couple2;
            partnerBStatus === ModuleStatus.completed ||
            checklistItemsForMember.find(
              (c) => c.type === ChecklistItemType.invitePartnerB
            )
              ? memberForEditing &&
                (setMemberType(memberForEditing.member_type),
                setMemberForEditing(memberForEditing),
                setAddModalOpen(true),
                setMobileMenuExpanded(true))
              : saveItem(
                  ChecklistItemType.invitePartnerB,
                  ceremony.id,
                  currentUser.id,
                  true
                );
          },
          hidden:
            currentUser?.member_type === MemberType.groom ||
            currentUser?.member_type === MemberType.bride ||
            currentUser?.member_type === MemberType.other,
        },
        {
          title: 'Invite your officiant',
          hidden: currentUser?.member_type === MemberType.officiant,
          buttonTitle:
            officiantStatus === ModuleStatus.completed ? '' : 'Invite',
          onButtonClick: () => {
            if (hasAccessToInviteOfficiant) {
              setMemberType(MemberType.officiant);
              setAddModalOpen(true);
              //setMobileMenuExpanded(true);
            } else {
              setIsPaywallModalOpen(true);
              setPaywallModalReferrer('officiant');
              setPaywallModalType('officiant');
            }
          },
          status: officiantStatus,
          textButtonTitle:
            officiantStatus === ModuleStatus.completed
              ? 'Edit'
              : "Don't have one",
          onTextButtonClick: () => {
            const memberForEditing = ceremony?.members
              ?.filter((m) => m.member_type === MemberType.officiant)
              .find((m) => m.id !== currentUser.id);
            officiantStatus === ModuleStatus.completed
              ? memberForEditing
                ? (setMemberType(memberForEditing.member_type),
                  setMemberForEditing(memberForEditing),
                  setAddModalOpen(true),
                  setMobileMenuExpanded(true))
                : (setAddModalOpen(true),
                  setMemberType(MemberType.officiant),
                  setMobileMenuExpanded(true))
              : saveItem(
                  ChecklistItemType.inviteOfficiant,
                  ceremony.id,
                  currentUser.id,
                  true
                );
          },
        },
        {
          title:
            currentUser?.member_type === MemberType.officiant
              ? 'Invite wedding planner'
              : 'Invite your wedding planner',
          buttonTitle:
            weddingPlannerStatus === ModuleStatus.completed ? '' : 'Invite',
          onButtonClick: () => {
            if (hasAccessToInviteWeddingPlanner) {
              setMemberType(MemberType.weddingPlanner);
              setAddModalOpen(true);
              //setMobileMenuExpanded(true);
            } else {
              setIsPaywallModalOpen(true);
              setPaywallModalReferrer('weddingPlanner');
              setPaywallModalType('weddingPlanner');
            }
          },
          status: weddingPlannerStatus,
          textButtonTitle:
            weddingPlannerStatus === ModuleStatus.completed
              ? 'Edit'
              : "Don't have one",
          onTextButtonClick: () => {
            const memberForEditing = ceremony?.members
              ?.filter((m) => m.member_type === MemberType.weddingPlanner)
              .find((m) => m.id !== currentUser.id);
            weddingPlannerStatus === ModuleStatus.completed
              ? memberForEditing
                ? (setMemberType(memberForEditing.member_type),
                  setMemberForEditing(memberForEditing),
                  setAddModalOpen(true),
                  setMobileMenuExpanded(true))
                : (setAddModalOpen(true),
                  setMobileMenuExpanded(true),
                  setMemberType(MemberType.weddingPlanner))
              : saveItem(
                  ChecklistItemType.inviteWeddingPlanner,
                  ceremony.id,
                  currentUser.id,
                  true
                );
          },
        },
      ],
      ['buildCeremony']: [
        {
          title: "Download Ceremony Script, then you're done!",
          buttonTitle: hasAccessToDeclarationOfIntent ? 'Download' : 'Unlock',
          onButtonClick: () => {
            hasAccessToDeclarationOfIntent
              ? navigate(`/ceremony-designer/${ceremony.id}`)
              : (setUpsellGif(UpsellGifTypes.ceremonyChapter5),
                setIsPaywallModalOpen(true),
                setPaywallModalReferrer('The “I Do’s”'),
                setPaywallModalType('ceremony'));
          },
          status: ceremonyDesignerStatus,
        },
      ],
      ['vows']: [
        {
          /* hidden: currentUser?.member_type === MemberType.officiant, */
          title: 'Complete your vows',
          buttonTitle: checklistItemsForMember.find(
            (item) => item.type === ChecklistItemType.completeVows
          )
            ? ''
            : hasAccessToVowsAi
            ? "I'm done"
            : 'Unlock',
          onButtonClick: () => {
            hasAccessToVowsAi
              ? saveItem(
                  ChecklistItemType.completeVows,
                  ceremony.id,
                  currentUser.id
                )
              : (setUpsellGif(UpsellGifTypes.vowsBuilder),
                setIsPaywallModalOpen(true),
                setPaywallModalReferrer('Vows'),
                setPaywallModalType('vows'));
          },
          textButtonTitle: checklistItemsForMember.find(
            (item) => item.type === ChecklistItemType.completeVows
          )
            ? hasAccessToVowsAi
              ? 'Review'
              : 'Unlock'
            : 'Skip this step',
          onTextButtonClick: () => {
            checklistItemsForMember.find(
              (item) => item.type === ChecklistItemType.completeVows
            )
              ? hasAccessToVowsAi
                ? navigate(`/ceremony/${ceremony.id}/vowbuilder`)
                : (setUpsellGif(UpsellGifTypes.vowsBuilder),
                  setIsPaywallModalOpen(true),
                  setPaywallModalReferrer('Vows'),
                  setPaywallModalType('vows'))
              : saveItem(
                  ChecklistItemType.completeVows,
                  ceremony.id,
                  currentUser.id,
                  true
                );
          },
          status: checklistItemsForMember.find(
            (item) => item.type === ChecklistItemType.completeVows
          )
            ? ModuleStatus.completed
            : ModuleStatus.notStarted,
        },
      ],
      ['speakers']: [
        {
          title: "Invite people you're trusting with the mic",
          buttonTitle: ceremony.members?.find(
            (m) => m.member_type === MemberType.guests
          )
            ? ''
            : checklistItemsForMember.find(
                (item) => item.type === ChecklistItemType.inviteGuests
              )
            ? ''
            : 'Invite',
          onButtonClick: () => {
            navigate(`/ceremony/${ceremony.id}/toastbuilder`);
          },
          textButtonTitle: ceremony.members?.find(
            (m) => m.member_type === MemberType.guests
          )
            ? 'Invite more speakers'
            : checklistItemsForMember.find(
                (item) => item.type === ChecklistItemType.inviteGuests
              )
            ? 'Invite'
            : 'Skip this step',
          onTextButtonClick: () => {
            ceremony.members?.find((m) => m.member_type === MemberType.guests)
              ? navigate(`/ceremony/${ceremony.id}/toastbuilder`)
              : checklistItemsForMember.find(
                  (item) => item.type === ChecklistItemType.inviteGuests
                )
              ? navigate(`/ceremony/${ceremony.id}/toastbuilder`)
              : saveItem(
                  ChecklistItemType.inviteGuests,
                  ceremony.id,
                  currentUser.id,
                  true
                );
          },
          status: checklistItemsForMember.find(
            (item) => item.type === ChecklistItemType.inviteGuests
          )
            ? ModuleStatus.completed
            : ceremony.members?.find((m) => m.member_type === MemberType.guests)
            ? ModuleStatus.completed
            : ModuleStatus.notStarted,
        },
        {
          title: 'Invite rehearsal dinner speaker',
          buttonTitle: ceremony.speakers?.find(
            (s) => s.event === EventType.rehearsalDinner
          )
            ? ''
            : checklistItemsForMember.find(
                (item) => item.type === ChecklistItemType.inviteGuestsReherseal
              )
            ? ''
            : 'Invite',
          onButtonClick: () => {
            navigate(`/ceremony/${ceremony.id}/toastbuilder`);
          },
          textButtonTitle: ceremony.speakers?.find(
            (s) => s.event === EventType.rehearsalDinner
          )
            ? 'Invite more speakers'
            : checklistItemsForMember.find(
                (item) => item.type === ChecklistItemType.inviteGuestsReherseal
              )
            ? 'Invite'
            : 'Skip this step',
          onTextButtonClick: () => {
            ceremony.speakers?.find(
              (s) => s.event === EventType.rehearsalDinner
            )
              ? navigate(`/ceremony/${ceremony.id}/toastbuilder`)
              : checklistItemsForMember.find(
                  (item) =>
                    item.type === ChecklistItemType.inviteGuestsReherseal
                )
              ? navigate(`/ceremony/${ceremony.id}/toastbuilder`)
              : saveItem(
                  ChecklistItemType.inviteGuestsReherseal,
                  ceremony.id,
                  currentUser.id,
                  true
                );
          },
          status: checklistItemsForMember.find(
            (item) => item.type === ChecklistItemType.inviteGuestsReherseal
          )
            ? ModuleStatus.completed
            : ceremony.speakers?.find(
                (s) => s.event === EventType.rehearsalDinner
              )
            ? ModuleStatus.completed
            : ModuleStatus.notStarted,
        },
        {
          title: 'Invite planner to manage toasts',
          buttonTitle:
            weddingPlannerStatus === ModuleStatus.completed ? '' : 'Invite',
          onButtonClick: () => {
            const memberForEditing = ceremony?.members
              ?.filter((m) => m.member_type === MemberType.weddingPlanner)
              .find((m) => m.id !== currentUser.id);
            if (memberForEditing) {
              setMemberType(memberForEditing.member_type);
              setMemberForEditing(memberForEditing);
              setAddModalOpen(true);
            }
          },
          status: weddingPlannerStatus,
          textButtonTitle:
            weddingPlannerStatus === ModuleStatus.completed
              ? 'Edit'
              : 'Skip this step',
          onTextButtonClick: () => {
            const memberForEditing = ceremony?.members
              ?.filter((m) => m.member_type === MemberType.weddingPlanner)
              .find((m) => m.id !== currentUser.id);
            weddingPlannerStatus === ModuleStatus.completed
              ? memberForEditing &&
                (setMemberType(memberForEditing.member_type),
                setMemberForEditing(memberForEditing),
                setAddModalOpen(true))
              : saveItem(
                  ChecklistItemType.inviteWeddingPlanner,
                  ceremony.id,
                  currentUser.id,
                  true
                );
          },
        },
      ],
      ['more']: [
        {
          title: checklist_more_element_1_title, // Change Your Name Without the Hassle
          buttonTitle:
            prenump === ModuleStatus.notStarted ? ' Get Started' : undefined,
          textButtonTitle:
            prenump === ModuleStatus.notStarted ? 'Not now' : ' View',
          onButtonClick: () => {
            void saveItem(
              ChecklistItemType.prenump,
              ceremony.id,
              currentUser.id
            );
            window.open('https://shrsl.com/41upn', '_blank'); // to hitchswitch
          },
          onTextButtonClick: () => {
            if (prenump === ModuleStatus.notStarted) {
              void saveItem(
                ChecklistItemType.prenump,
                ceremony.id,
                currentUser.id,
                true
              );
            } else window.open('https://shrsl.com/41upn', '_blank'); // to hitchswitch
          },
          hidden: currentRole === MemberType.officiant,
          status: prenump,
        },
        {
          title: checklist_more_element_2_title, // Submit Your Marriage License Online
          buttonTitle:
            legalServicesStatus === ModuleStatus.notStarted
              ? ' Get Started'
              : undefined,
          textButtonTitle:
            legalServicesStatus === ModuleStatus.notStarted
              ? 'Not now'
              : ' View',
          onButtonClick: () => {
            void saveItem(
              ChecklistItemType.legalServices,
              ceremony.id,
              currentUser.id
            );
            window.open('https://shrsl.com/438dy', '_blank'); //to courtly
          },
          onTextButtonClick: () => {
            if (legalServicesStatus === ModuleStatus.notStarted) {
              void saveItem(
                ChecklistItemType.legalServices,
                ceremony.id,
                currentUser.id,
                true
              );
            } else window.open('https://shrsl.com/438dy', '_blank'); // to courtly
          },
          status: legalServicesStatus,
        },
        {
          title: checklist_more_element_3_title, // Get a State-Specific Prenuptal Agreement
          buttonTitle:
            premaritalCounselingStatus === ModuleStatus.notStarted
              ? ' Get Started'
              : undefined,
          textButtonTitle:
            premaritalCounselingStatus === ModuleStatus.notStarted
              ? 'Not now'
              : ' View',
          onButtonClick: () => {
            void saveItem(
              ChecklistItemType.premaritalCounseling,
              ceremony.id,
              currentUser.id
            );
            window.open(
              'https://www.helloprenup.com/?via=provenance',
              '_blank'
            );
          },
          onTextButtonClick: () => {
            if (premaritalCounselingStatus === ModuleStatus.notStarted) {
              void saveItem(
                ChecklistItemType.premaritalCounseling,
                ceremony.id,
                currentUser.id,
                true
              );
            } else
              window.open(
                'https://www.helloprenup.com/?via=provenance',
                '_blank'
              );
          },
          status: premaritalCounselingStatus,
          hidden: currentRole === MemberType.officiant,
        },
        {
          title: 'Get ordained', // Get a State-Specific Prenuptal Agreement
          buttonTitle:
            getOrdainedStatus === ModuleStatus.notStarted
              ? ' Get Started'
              : undefined,
          textButtonTitle:
            getOrdainedStatus === ModuleStatus.notStarted ? 'Not now' : ' View',
          onButtonClick: () => {
            void saveItem(
              ChecklistItemType.getOrdained,
              ceremony.id,
              currentUser.id
            );
            window.open('https://theamm.org/', '_blank');
          },
          onTextButtonClick: () => {
            if (getOrdainedStatus === ModuleStatus.notStarted) {
              void saveItem(
                ChecklistItemType.getOrdained,
                ceremony.id,
                currentUser.id,
                true
              );
            } else window.open('https://theamm.org/', '_blank');
          },
          status: getOrdainedStatus,
          hidden: currentRole !== MemberType.officiant,
        },
      ],
    };

    // setSubItems(subChecklistItems);

    const incompleteChecklistItems: number[] = [];

    const easyWinsIncompleteItems = subChecklistItems['easyWins'].find(
      (item) => {
        if (item.status !== ModuleStatus.completed && item.hidden !== true) {
          incompleteChecklistItems.push(1);

          return true;
        }

        return false;
      }
    );

    if (!easyWinsIncompleteItems) {
      setEasyWinsStatus(ModuleStatus.completed);
    }

    const buildCeremonyIncompleteItems = subChecklistItems[
      'buildCeremony'
    ].find((item) => {
      if (item.status !== ModuleStatus.completed && item.hidden !== true) {
        incompleteChecklistItems.push(2);
        return true;
      }
      return false;
    });

    if (!buildCeremonyIncompleteItems) {
      setCeremonyDesignerStatus(ModuleStatus.completed);
    }

    const vowsIncompleteItems = subChecklistItems['vows'].find((item) => {
      if (item.status !== ModuleStatus.completed && item.hidden !== true) {
        incompleteChecklistItems.push(3);

        return true;
      }

      return false;
    });

    if (!vowsIncompleteItems) {
      setVowsStatus(ModuleStatus.completed);
    }

    const speakersIncompleteItems = subChecklistItems['speakers'].find(
      (item) => {
        if (item.status !== ModuleStatus.completed && item.hidden !== true) {
          incompleteChecklistItems.push(4);

          return true;
        }

        return false;
      }
    );

    if (!speakersIncompleteItems) {
      setToastsStatus(ModuleStatus.completed);
    }

    const moreIncompleteItems = subChecklistItems['more'].find((item) => {
      if (item.status !== ModuleStatus.completed && item.hidden !== true) {
        incompleteChecklistItems.push(5);

        return true;
      }

      return false;
    });

    if (!moreIncompleteItems) {
      setMoreStatus(ModuleStatus.completed);
    }

    const minIncompleteItem = Math.min(...incompleteChecklistItems);

    !userSetExpandedChecklistItem &&
      setExpandedChecklistItem(minIncompleteItem);
  };

  const { fetchQaStatus, QAStatus, getPercentageByModule } = useQAStatus();
  const { speakerEvents } = useSpeakers({
    ceremonyId: ceremony?.id ?? 0,
  });

  const fetchChecklistItems = async (ceremonyId: string) => {
    try {
      const response = await getChecklistItemsForCeremony(ceremonyId);

      if (response.success) {
        const checklistItemsResult = response.data;
        const checklistItemsForMember = checklistItemsResult.filter(
          (ci) => ci.member_id === currentUser?.id
        );
        setChecklistItemsForMember(checklistItemsForMember);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (ceremony) {
      void fetchQaStatus(ceremony.id.toString());
    }
  }, [ceremony?.id]);

  useEffect(() => {
    const ceremonyDesignerChecklistItem = checklistItemsForMember.find(
      (ci) => ci.type === ChecklistItemType.ceremonyDesigner
    );
    const directionalPercentage = getPercentageByModule(
      Module.DirectionalQuestions
    );
    const remarksPercentage = getPercentageByModule(Module.OfficiantRemarks);
    const readingsPercentage = getPercentageByModule(Module.RitualsAndReadings);
    const declarationPercentage = getPercentageByModule(
      Module.DeclarationOfIntent
    );

    if (
      ceremonyDesignerChecklistItem &&
      directionalPercentage === 100 &&
      remarksPercentage === 100 &&
      readingsPercentage === 100 &&
      declarationPercentage === 100
    ) {
      //completed only when this is true (after PDF downoad)
      setCeremonyDesignerStatus(ModuleStatus.completed);
    } else {
      if (
        directionalPercentage === 0 &&
        remarksPercentage === 0 &&
        readingsPercentage === 0 &&
        declarationPercentage === 0
      ) {
        setCeremonyDesignerStatus(ModuleStatus.notStarted);
      } else {
        setCeremonyDesignerStatus(ModuleStatus.inProgress);
      }

      if (
        checklistItemsForMember.find(
          (ci) => ci.type === ChecklistItemType.weddingDate
        ) ||
        ceremony?.date
      ) {
        setWeddingDateStatus(ModuleStatus.completed);
      }

      if (
        ceremony?.members
          ?.filter((m) => {
            return (
              (m.member_type === MemberType.groom ||
                m.member_type === MemberType.bride ||
                m.member_type === MemberType.other) &&
              currentUser &&
              m.mem_id !== currentUser.id
            );
          })
          ?.every((member) => {
            return member.email !== null && member.email !== '';
          })
      ) {
        setPartnerStatus(ModuleStatus.completed);
      }

      if (couple1 && couple1.email !== null && couple1?.email !== '') {
        setPartnerAStatus(ModuleStatus.completed);
      }
      if (couple2 && couple2.email !== null && couple2?.email !== '') {
        setPartnerBStatus(ModuleStatus.completed);
      }

      if (
        ceremony?.members
          ?.filter((m) => m.member_type === MemberType.officiant)
          .some((member) => {
            return member.email !== null;
          }) ||
        checklistItemsForMember.find(
          (ci) => ci.type === ChecklistItemType.inviteOfficiant
        )
      ) {
        setOfficiantStatus(ModuleStatus.completed);
      }

      if (
        ceremony?.members
          ?.filter((m) => m.member_type === MemberType.weddingPlanner)
          .some((member) => {
            return member.email !== null;
          }) ||
        checklistItemsForMember.find(
          (ci) => ci.type === ChecklistItemType.inviteWeddingPlanner
        )
      ) {
        setWeddingPlannerStatus(ModuleStatus.completed);
      }

      if (
        ceremony?.venue ||
        checklistItemsForMember.find(
          (ci) => ci.type === ChecklistItemType.includeVenue
        )
      ) {
        setVenueStatus(ModuleStatus.completed);
      }
    }
  }, [QAStatus, checklistItemsForMember, currentUser]);

  useEffect(() => {
    if (speakerEvents && speakerEvents.length > 0) {
      setToastsStatus(ModuleStatus.completed);
    } else {
      const toastsChecklistItem = checklistItemsForMember.find(
        (ci) => ci.type === ChecklistItemType.toasts
      );
      if (toastsChecklistItem) {
        setToastsStatus(ModuleStatus.completed);
      }
    }
  }, [speakerEvents, checklistItemsForMember]);

  const saveItem = async (
    type: ChecklistItemType,
    ceremonyId: number,
    memberId?: number,
    skipped?: boolean
  ) => {
    if (memberId) {
      const checklistItemForSaving: ChecklistItem = {
        ceremony_id: ceremonyId,
        member_id: memberId,
        type: type,
        skipped: skipped,
      };
      const response = await saveChecklistItem(checklistItemForSaving);

      if (response.success) {
        void fetchChecklistItems(ceremonyId.toString());
      }
    }
  };

  useEffect(() => {
    if (ceremony && currentUser) {
      void fetchChecklistItems(ceremony.id.toString());
    }
  }, [ceremony, currentUser]);

  const getIntroJSSteps = () => {
    if (currentRole === MemberType.officiant) {
      return introJsStepsOfficiant;
    }

    return introJsSteps;
  };

  return ceremony && currentUser ? (
    <div className='CeremonyChecklist-root'>
      {showTourModal && (
        <>
          <TourModal
            title='Welcome to Provenance'
            text={renderText('tour_modal_text')}
            isOpen={showTourModal}
            onClose={() => setShowTourModal(false)}
            onStartTourClick={() => {
              setShowTourModal(false);
              setShowTour(true);
            }}
          />
        </>
      )}
      {showGoToPaidCeremonyModal && (
        <GoToPaidCeremonyModal
          title={`You are currently viewing ${capitalizeFirstLetterOfEachWord(
            couple1?.preferred_name || ''
          )} & ${capitalizeFirstLetterOfEachWord(
            couple2?.preferred_name || ''
          )}'s wedding ceremony`}
          description={`You have another ceremony attached to your account that is upgraded.`}
          isOpen={showGoToPaidCeremonyModal}
          onClose={() => setShowGoToPaidCeremonyModal(false)}
          onGoToPaidCeremonyClick={() => {
            setShowGoToPaidCeremonyModal(false);
            if (paidCeremonies)
              navigate(`/ceremony/${paidCeremonies[0].id}/checklist`);
          }}
          onDontShowAgainKey={hideGoToPaidCeremonyKey}
        />
      )}
      <Steps
        enabled={showTour}
        steps={getIntroJSSteps()}
        initialStep={0}
        onExit={() => {
          void handleDoneTourClick();
        }}
        options={{
          doneButton: 'Done',
        }}
      />
      <div className='CeremonyChecklist-header'>
        <div>
          <Typography type='display-400' className='pb-2'>
            {`${capitalizeFirstLetterOfEachWord(
              couple1?.preferred_name || ''
            )} & ${capitalizeFirstLetterOfEachWord(
              couple2?.preferred_name || ''
            )}'s Ceremony Checklist`}
          </Typography>
          <Typography type='body-400' className='max-w-[540px]'>
            Your go-to checklist for crafting personalized, heartfelt moments
            for your wedding, whether it's for the ceremony, vows, toasts, or
            beyond.
          </Typography>
        </div>
        {currentRole !== MemberType.officiant && (
          <div className='block'>
            <div className='flex items-center'>
              <div className='mr-4 w-[75px]'>
                {circularProgressBar(
                  completedChecklistItemsCount,
                  checklistItems.length
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {/* <div className='CeremonyChecklist-header justify-end'> */}
      <div className='flex flex-row justify-end'>
        {/* <div className='flex flex-row'>
          <ButtonPill
            text={"Couple's tasks"}
            selected={tasksToShow === 'couple'}
            onClick={() => setTasksToShow('couple')}
          />
          <ButtonPill
            text={"Officiant's tasks"}
            selected={tasksToShow === 'officiant'}
            onClick={() => setTasksToShow('officiant')}
          />
        </div> */}
        <div className='flex flex-row'>
          <CustomSwitch
            checked={showCompleted}
            onChange={() => setShowCompleted(!showCompleted)}
          />
          <Typography type='body-400' className='ml-2'>
            Show completed
          </Typography>
        </div>
      </div>
      {giftcard && (
        <>
          <GiftcardBanner />
          <RedeemGiftcardModal />
        </>
      )}
      <div className={showCompleted ? '' : 'hide-completed'}>
        {ceremony && tasksToShow === 'couple' && (
          <CoupleChecklistItems
            ceremonyId={ceremony.id}
            currentUser={currentUser}
            couple1={couple1}
            couple2={couple2}
          />
        )}
        {ceremony && tasksToShow === 'officiant' && (
          <OfficiantChecklistItems
            ceremonyId={ceremony.id}
            currentUser={currentUser}
            couple1={couple1}
            couple2={couple2}
          />
        )}
      </div>
      <VideoModal />
      <PaywallModal
        isOpen={isPaywallModalOpen}
        onClose={() => setIsPaywallModalOpen(false)}
        type={paywallModalType}
        referrer={paywallModalReferrer}
        ceremonyId={ceremony.id}
        displayGif={upsellGif}
      />
    </div>
  ) : (
    <></>
  );
};
