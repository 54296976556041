import { Divider, ListItemIcon, Menu, MenuItem } from '@mui/material';
import React from 'react';

import chevronDownSvg from '../../../assets/images/icons/chevron-down.svg';
import chevronUpSvg from '../../../assets/images/icons/chevron-up.svg';

import './accountMenu.scss';

import { Typography } from '../Typography/Typography';

import { Logout, SettingsOutlined, ArticleOutlined } from '@mui/icons-material';

import { useAuth } from '../../../provider/authProvider';

import { useNavigate } from 'react-router-dom';

import { useDashboard } from '../../../provider/dashboardProvider';

type AccountMenuProps = {
  name: string;
  expanded: boolean;
};

export const AccountMenu = (props: AccountMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const auth = useAuth();
  const { ordained } = useDashboard();

  const signOut = () => {
    auth.signout();
    navigate('/');
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { name, expanded } = props;
  return (
    <div className='AccountMenu-root'>
      <div className='AccountMenu-avatar'>
        <Typography variant='functional-low' type='body-100'>
          {name[0]}
        </Typography>
      </div>
      {expanded && (
        <div className='ml-4'>
          <Typography variant='functional-low' type='body-400'>
            {name}
          </Typography>
        </div>
      )}
      {expanded && (
        <div className='ml-auto'>
          <img
            className='cursor-pointer'
            onClick={handleClick}
            src={open ? chevronUpSvg : chevronDownSvg}
          />
          <Menu
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            PaperProps={{
              style: {
                width: 224,
                backgroundColor: '#EFEDE7',
                boxShadow:
                  '0px 1px 8px rgba(53, 71, 64, 0.16), 0px 2px 3px rgba(53, 71, 64, 0.08)',
                borderRadius: 0,
                borderWidth: '1px',
                borderColor: '#9AA3A0',
              },
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={() => navigate('/settings')}>
              <div className='AccountMenu-avatar mr-4'>
                <Typography variant='functional-low' type='body-100'>
                  {name[0]?.toUpperCase()}
                </Typography>
              </div>
              <Typography variant='functional-low' type='body-400'>
                Account Settings
              </Typography>
            </MenuItem>

            <MenuItem onClick={() => navigate('/settings?tab=my%20events')}>
              <ListItemIcon>
                <SettingsOutlined className='mr-5' fontSize='small' />
              </ListItemIcon>
              <Typography variant='functional-low' type='body-400'>
                Ceremony Settings
              </Typography>
            </MenuItem>

            {ordained && (
              <MenuItem onClick={() => navigate('/settings?tab=ordination')}>
                <ListItemIcon>
                  <ArticleOutlined className='mr-5' fontSize='small' />
                </ListItemIcon>
                <Typography variant='functional-low' type='body-400'>
                  Ordination Settings
                </Typography>
              </MenuItem>
            )}

            <Divider />

            <MenuItem
              onClick={() => {
                signOut();
              }}
            >
              <ListItemIcon>
                <Logout className='mr-5' fontSize='small' />
              </ListItemIcon>
              <Typography variant='functional-low' type='body-400'>
                Log Out
              </Typography>
            </MenuItem>
          </Menu>
        </div>
      )}
    </div>
  );
};
