/* eslint-disable */

import Button from '../../components/v2/Button/Button';
import { Typography } from '../../components/v2/Typography/Typography';
import { useWindowSize } from '../../hooks/useWindowSize';
import LanderItem from '../../components/LanderItem/LanderItem';

import background1Png from './background-1.png';
import background2Png from './background-2.png';
import background3Png from './background-3.png';
import listPng from './list.png';
import planePng from './plane.png';
import laptop from './laptop.png';
import tynIcon from './tynIcon.png';
import {
  RedeemerApps,
  generateAuthToken,
  generateExternalApiKey,
} from '../../api/auth';
import { useSnackbar } from '../../provider/snackbarProvider';

import { useDashboard } from '../../provider/dashboardProvider';
import { useChecklist } from '../../provider/checklistProvider';
import { ChecklistItemIdentifier } from '../../helpers/checklistHelpers/identifiers';
import { navigateToApp } from '../../helpers/redirectToAppHelper';
import VideoModal from '../../components/v2/VideoModal/VideoModal';
import { useAuth } from '../../provider/authProvider';

const ThankYouNotesLander = () => {
  const size = useWindowSize();

  const { openSnackBar } = useSnackbar();

  const { user } = useAuth();

  const {
    ceremony,
    currentUser,
    setRedeemerApp,
    setEnterPasswordModalOpen,
    setYoutubeVideoModalOpen,
    setYoutubeVideoUrl,
    setLastNameModalOpen,
  } = useDashboard();
  const { saveMemberChecklistItemUsingIdentifier } = useChecklist();

  const onGetStartedClick = async () => {
    if (!ceremony) return openSnackBar('Ceremony not found', 'error');

    if (ceremony && currentUser) {
      void saveMemberChecklistItemUsingIdentifier(
        {
          ceremony_id: ceremony?.id,
          member_id: currentUser?.id,
          completed: true,
        },
        ChecklistItemIdentifier.WRITE_THANK_YOU_NOTES
      );
    }

    if (user && !user.last_name) {
      setRedeemerApp(RedeemerApps.THANKYOU);
      setLastNameModalOpen(true);
    } else {
      try {
        openSnackBar('Creating secure connection...', 'success');
        await navigateToApp(ceremony.id, RedeemerApps.THANKYOU);
      } catch (err) {
        console.log('err', err);
        setRedeemerApp(RedeemerApps.THANKYOU);
        setEnterPasswordModalOpen(true);
      }
    }
  };

  return (
    <div className=' flex flex-col items-center text-center gap-6 mt-10 lg:mt-0'>
      <img className='h-16 w-16' alt='baby' src={tynIcon} />
      <div>
        <Typography type={size.isMobile ? 'display-600' : 'display-1000'}>
          Thank You
        </Typography>
        <Typography type={size.isMobile ? 'display-600' : 'display-1000'}>
          Notes
        </Typography>
        <Typography type='body-600' className='max-w-xl mt-6'>
          Save time and stress without sacrificing the heartfelt touch. Use the
          Thank You Note Builder to generate personalized messages, and send
          200+ custom wedding thank you notes in minutes.
        </Typography>
      </div>

      <div className='w-full items-center justify-center flex flex-col gap-2 my-6 '>
        <div className='w-full items-center justify-center flex gap-2'>
          <Button
            fullWidth={size.isMobile}
            renderRightIcon={() => (
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M20 4.8315V15.9697C20 16.4108 19.6422 16.7678 19.2 16.7678C18.7578 16.7678 18.4 16.4108 18.4 15.9697V6.76185L5.36489 19.7656C5.20938 19.9202 5.00438 20 4.79988 20C4.59538 20 4.39048 19.9221 4.23438 19.7662C3.92187 19.4544 3.92187 18.9491 4.23438 18.6379L17.2795 5.62419L7.99941 5.59652C7.5554 5.59466 7.1984 5.23553 7.1999 4.79658C7.20147 4.35544 7.5593 4 7.99991 4L19.2 4.03273C19.645 4.03541 20 4.39256 20 4.8315Z'
                  fill='#efede7'
                />
              </svg>
            )}
            className='vow-benefit-placeholder'
            onClick={onGetStartedClick}
          >
            Get Started
          </Button>

          <Button
            fullWidth={size.isMobile}
            renderLeftIcon={() => (
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <g clipPath='url(#clip0_6320_6281)'>
                  <path
                    d='M16.7109 11.0391C17.0438 11.2453 17.25 11.6062 17.25 12C17.25 12.3938 17.0438 12.7547 16.7109 12.9609L9.96094 17.0859C9.61406 17.2969 9.17813 17.3062 8.82656 17.1047C8.47031 16.9078 8.25 16.5328 8.25 16.125V7.83281C8.25 7.46719 8.47031 7.09219 8.82656 6.89531C9.17813 6.69375 9.61406 6.66094 9.96094 6.91406L16.7109 11.0391ZM9.75 8.54531V15.4547L15.4078 11.9578L9.75 8.54531ZM0 12C0 5.37188 5.37188 0 12 0C18.6281 0 24 5.37188 24 12C24 18.6281 18.6281 24 12 24C5.37188 24 0 18.6281 0 12ZM12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5Z'
                    fill='#354740'
                  />
                </g>
                <defs>
                  <clipPath id='clip0_6320_6281'>
                    <rect width='24' height='24' fill='white' />
                  </clipPath>
                </defs>
              </svg>
            )}
            variant='secondary'
            className='px'
            onClick={() => {
              setYoutubeVideoUrl('kG7Mf3mm-tQ');
              setYoutubeVideoModalOpen(true);
            }}
          >
            Watch Demo
          </Button>
        </div>

        <Typography type='body-100' className='mt-2 text-[#9AA3A0]'>
          You will be redirected to the Thank You Note Builder app.
        </Typography>
      </div>
      <div className='w-full flex flex-col justify-center items-center md:flex-row gap-3 flex-wrap'>
        <LanderItem
          borderSvgSource={background1Png}
          iconSvgSource={listPng}
          text='Upload Your List Of Gifts & Gifters'
          textColor='text-[#324E54]'
        />
        <LanderItem
          borderSvgSource={background2Png}
          iconSvgSource={laptop}
          text='Add Personal Touches & Generate Drafts'
          shiftDown={size.isMobile ? false : true}
          textColor='text-[#324E54]'
        />
        <LanderItem
          borderSvgSource={background3Png}
          iconSvgSource={planePng}
          text='Robots Will Write & Mail Every Note'
          textColor='text-[#324E54]'
        />
      </div>

      <VideoModal />
    </div>
  );
};

export default ThankYouNotesLander;
