/* eslint-disable -- TODO: fix eslint errors */
import { getApiToken, delay } from '../helpers/helper';

export const BASE_URL = process.env.REACT_APP_BASE_URL + 'api/';

type GetProps = {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  url: string;
  additionalHeaders?: Record<string, string>;
  useRelativeUrl?: boolean;
  body?: Record<string, any>;
  useAuth?: boolean;
};

type PostProps = GetProps & {
  body: Record<string, any>;
};

type RetryOptions = {
  /**
   * Number of maximum retries.
   * @default 3
   */
  maxRetries?: number;
  /**
   * Delay in seconds between retries, not applied for the last retry.
   * @default 0
   */
  delayBetweenRetries?: number;
  /**
   * Delay in seconds for last retry request.
   * @default 30
   */
  lastRetryDelay?: number;
  /**
   * Internal usage.
   */
  retryCount?: number;
}

type RequestProps = GetProps | PostProps;

function debugLog(...args: any[]) {
  if (process.env.NODE_ENV !== 'production') {
    console.log(...args);
  }
}

function logout() {
  localStorage.clear();
  location.replace('/');
}

export async function _request<T>({
  method,
  url,
  additionalHeaders = {},
  body,
  useRelativeUrl = true,
}: RequestProps): Promise<T> {
  let reqBody = { ...body };

  let DEFAULT_HEADERS: any = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };
  const token = getApiToken();
  if (token) {
    DEFAULT_HEADERS = {
      ...DEFAULT_HEADERS,
      Authorization: 'Bearer ' + token,
    };
  }

  let headers = { ...DEFAULT_HEADERS, ...additionalHeaders };

  //debugLog(`Request body for ${url}`, reqBody);

  const payload = JSON.stringify(reqBody || {});
  const controller = new AbortController();
  
  let timeoutTime = 60000;
  if(url.includes('ai/')){
    timeoutTime = 600000;
  }

  setTimeout(() => controller.abort(), timeoutTime);

  const response = await fetch(useRelativeUrl ? `${BASE_URL}${url}` : url, {
    method,
    headers,
    signal: controller.signal,
    ...(method === 'GET' ? {} : { body: payload }),
  });
  const responseBody =
    response.headers.get('content-type') ===
      'application/json; charset=utf-8' ||
    response.headers.get('content-type') === 'application/json'
      ? await response.json()
      : response.headers.get('content-type') === 'application/pdf'
      ? await response.blob()
      : await response.text();

  if (response.status === 401) {
    const REFRESH_TOKEN_ERROR_MESSAGES = [
      'No/Invalid Token, Auth is denied.',
      'User is deleted/banned',
      'User is not active',
      'User access is denied',
      'User does not exists',
      'System could not authenticate user',
      'User not logged in'
    ];

    if (REFRESH_TOKEN_ERROR_MESSAGES.includes(responseBody.message)) {
      logout();
      // auth.signout();
      // navigate('/');
    }
  }
  if (response.status >= 300) {
    throw responseBody;
  }

  return responseBody;
}

export async function _requestWithRetry<T>(requestProps: RequestProps, retryOptions?: RetryOptions): Promise<T> {
  try {
    debugLog('Making request with retry strategy.');
    return await _request<T>(requestProps);
  } catch (err) {
    const retryCount = retryOptions?.retryCount ?? 0;
    const maxRetries = retryOptions?.maxRetries ?? 3;

    // retries exceeded
    if (retryCount > (maxRetries - 1))
      throw err;

    // retries but not applied for last retry.
    if (retryCount < (maxRetries - 1)) {
      const delayBetweenRetries = retryOptions?.delayBetweenRetries ?? 0;
      debugLog(`Retrying request in ${delayBetweenRetries} seconds.`);
      await delay(delayBetweenRetries);
      return _requestWithRetry(requestProps, {
        ...retryOptions,
        retryCount: retryCount + 1,
      });
    }

    // delay for the last retry
    const lastRetryDelay = retryOptions?.lastRetryDelay ?? 30
    debugLog(`Retrying request in ${lastRetryDelay} seconds.`);
    await delay(lastRetryDelay);

    return _requestWithRetry<T>(requestProps, {
      ...retryOptions,
      retryCount: retryCount + 1,
    })
  }
}