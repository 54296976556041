import { Divider, ListItemIcon, } from '@mui/material';
import{ useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Drawer } from '../../../pages/ModulesV2/components/Drawer/Drawer';
import { useAuth } from '../../../provider/authProvider';
import Button from '../Button/Button';

import './accountMenu.scss';

import { Typography } from '../Typography/Typography';

import { Logout, SettingsOutlined, ArticleOutlined } from '@mui/icons-material';

import { useDashboard } from '../../../provider/dashboardProvider';

type AccountMenuProps = {
  name: string;
  expanded: boolean;
};

export const MobileAccountMenu = (props: AccountMenuProps) => {
  const [open, setOpen] = useState(false);
  const { ordained } = useDashboard();

  const navigate = useNavigate();
  const auth = useAuth();

  const signOut = () => {
    auth.signout();
    navigate('/');
  };

  const { name } = props;
  return (
    <>
      <div onClick={() => setOpen(true)} className='AccountMenu-root'>
        <div className='AccountMenu-avatar'>
          <Typography variant='functional-low' type='body-100'>
            {name[0]}
          </Typography>
        </div>
      </div>
      <Drawer open={open} onClose={() => setOpen(false)}>
        <div className='p-8'>
          <div
            className='cursor-pointer flex flex-row  py-6'
            onClick={() => navigate('/settings')}
          >
            <div className='AccountMenu-avatar mr-4'>
              <Typography variant='functional-low' type='body-100'>
                {name[0]}
              </Typography>
            </div>
            <Typography variant='functional-low' type='body-400'>
              Account Settings
            </Typography>
          </div>

          <div
            className='cursor-pointer flex flex-row items-center py-6'
            onClick={() => navigate('/settings?tab=my%20events')}
          >
            <div className=' mr-4'>
              <SettingsOutlined className='' fontSize='small' />
            </div>
            <Typography variant='functional-low' type='body-400'>
              Ceremony Settings
            </Typography>
          </div>

          {ordained && (
            <div
              className='cursor-pointer flex flex-row items-center py-6'
              onClick={() => navigate('/settings?tab=ordination')}
            >
              <div className=' mr-4'>
                <ArticleOutlined className='' fontSize='small' />
              </div>
              <Typography variant='functional-low' type='body-400'>
                Ordination Settings
              </Typography>
            </div>
          )}

          <Divider />
          <div
            className='cursor-pointer flex flex-row py-6'
            onClick={() => {
              signOut();
            }}
          >
            <ListItemIcon className='min-w-0'>
              <Logout className='mr-5' fontSize='small' />
            </ListItemIcon>
            <Typography variant='functional-low' type='body-400'>
              Log Out
            </Typography>
          </div>

          <Button
            className='w-full'
            variant='secondary'
            onClick={() => setOpen(false)}
          >
            Close
          </Button>
        </div>
      </Drawer>
    </>
  );
};
