import React, { useState } from 'react';

import { updateLastName } from '../../../api/auth';
import { getAPIErrorMessage } from '../../../helpers/helper';
import {
  getAppTitleByType,
  navigateToApp,
} from '../../../helpers/redirectToAppHelper';
import { useAuth } from '../../../provider/authProvider';
import { useDashboard } from '../../../provider/dashboardProvider';
import { useSnackbar } from '../../../provider/snackbarProvider';
import CustomTextInput from '../../forms/CustomTextInput';
import Button from '../Button/Button';
import { Modal } from '../Modal/Modal';
import { Typography } from '../Typography/Typography';

const LastNameModal = () => {
  const [lastName, setLastName] = useState('');
  const {
    lastNameModalOpen,
    setLastNameModalOpen,
    setEnterPasswordModalOpen,
    redeemerApp,
    ceremony,
  } = useDashboard();

  const { getProfile } = useAuth();

  const { openSnackBar } = useSnackbar();

  const onDoneClick = async () => {
    try {
      const updateLastNameResponse = await updateLastName({
        last_name: lastName,
      });
      if (updateLastNameResponse.success) {
        await getProfile(() => {});
        if (redeemerApp && ceremony) {
          setLastNameModalOpen(false);
          try {
            openSnackBar('Creating secure connection...', 'success');
            await navigateToApp(ceremony.id, redeemerApp);
          } catch (err) {
            console.log('err', err);
            setEnterPasswordModalOpen(true);
          }
        }
      } else {
        //hideLoader();
        openSnackBar(updateLastNameResponse.message, 'error');
      }
      //update user last name
    } catch (err) {
      //hideLoader();
      openSnackBar(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getAPIErrorMessage(err as any),
        'error'
      );
    }
  };

  return (
    <Modal
      isOpen={lastNameModalOpen}
      onClose={() => setLastNameModalOpen(false)}
    >
      <>
        <Typography type='display-600'>Enter your last name</Typography>
        <Typography type='body-400' className='mt-2 mb-8 text-center'>
          {`Enter your last name below to gain access to
          ${redeemerApp ? getAppTitleByType(redeemerApp) : ''}.`}
        </Typography>
        <CustomTextInput
          fullWidth={true}
          label='Last Name'
          placeholder='Enter Last Name'
          className='w-full mx-0'
          value={lastName}
          onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
            setLastName(ev.target.value);
          }}
        />
        <div className='flex flex-row justify-between items-center mt-8'>
          <Button disabled={!lastName} onClick={onDoneClick}>
            Done
          </Button>
        </div>
      </>
    </Modal>
  );
};

export default LastNameModal;
